<template>
    <div class="content">
        <div class="contentbox">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="资料登记" name="0">
                </el-tab-pane>
                <el-tab-pane label="资料审核中" name="1">
                </el-tab-pane>
                <el-tab-pane label="资料审核完成" name="2">
                </el-tab-pane>
            </el-tabs>
            <div class="sonContent">
                <div class="SearchBar" style="display: flex">
                    <span style="margin: 6px 10px 0 20px ">工程编号/名称:</span>
                    <el-input style="width: 200px" placeholder="请输入" size="small"  v-model="searchBox.keyWord"></el-input>
                    <span style="margin: 6px 10px 0 20px " v-if="activeName==2"> 工程类型：</span>
                    <el-select
                            v-if="activeName==2"
                            v-model="searchBox.projectType"
                            clearable
                            placeholder="请选择"
                            size="small"
                    >
                        <el-option
                                v-for="item in typeList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                        >
                        </el-option>
                    </el-select>
                    <el-button type="primary" class="SearchBtn" size="small" @click="search" style="margin-left: 10px">查询</el-button>
                </div>
                <!--数据表格-->
                <el-table
                        ref="tableData"
                        :data="tableData"
                        style="width: 100%;margin: 15px 0;"
                        border
                        height="calc(100vh - 380px)"
                        :stripe="true">
                    <el-table-column type="index" label="序号" width="70" align="center"> </el-table-column>
                    <el-table-column prop="projectNumber" label="工程编号" align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="projectName" label="工程名称" align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="projectTypeName" label="工程类型" align="center" show-overflow-tooltip v-if="activeName==2"></el-table-column>
                    <el-table-column prop="constructionTeam" label="第三方施工队" align="center" show-overflow-tooltip v-if="activeName!=2"></el-table-column>
                  <el-table-column prop="constructionTeamType" label="施工队类型" width="150" align="center" show-overflow-tooltip v-if="activeName!=2">
                    <template slot-scope="{row}">
                      <el-tag v-if="row.constructionTeamType==1">安装施工队</el-tag>
                      <el-tag v-if="row.constructionTeamType==2">土方施工队</el-tag>
                      <el-tag v-if="row.constructionTeamType==3">定向钻施工队</el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column prop="siteManager" label="现场管理员" align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="streamTime" label="创建时间" width="150" align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column label="审核情况" align="center" show-overflow-tooltip v-if="activeName==1">
                        <template slot-scope="{row}">
                            <span>
                                共{{ row.templateNumber }}项
                            </span>
                            <i class="el-icon-success" style="color: #67C23A"></i>{{row.transit}}
                            <i class="el-icon-success" style="color:#909399"></i>{{ row.unchecked }}
                            <i class="el-icon-success" style="color: #F56C6C"></i>{{ row.rejected }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" label="操作"  align="left" width="300px">
                        <template slot-scope="{row}">
                            <el-button v-if="activeName==0"   type="primary" size="small" @click="dengji(row,1)">登记资料</el-button>
                            <el-button v-if="activeName==1&&row.rejected>0" type="primary" size="small" @click="dengji(row,2)">修改资料</el-button>
                            <el-button v-if="activeName==1&&row.rejected>0" type="primary" size="small" @click="submitMenu(row)">提交审核</el-button>
                            <el-button v-if="activeName==0"   type="primary" size="small" @click="submitMenu(row)">提交审核</el-button>
                            <el-button v-if="activeName==1"   type="primary" size="small" @click="dengji(row,3)">查看</el-button>
                            <el-button v-if="activeName==2&&row.constructionTeamType==1"  type="primary" size="small" @click="dengji(row,3)">查看</el-button>
                            <el-button v-if="activeName==2&&row.constructionTeamType==2"   type="primary" size="small" @click="dengji(row,3)">查看</el-button>
                            <el-button v-if="activeName==2&&row.constructionTeamType==3"   type="primary" size="small" @click="dengji(row,3)">查看</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pageBox">
                    <el-pagination
                            :current-page="searchBox.current"
                            :background="true"
                            :page-sizes="[30, 50, 100]"
                            :page-size="searchBox.size"
                            layout="total, prev, pager, next, sizes, jumper"
                            :total="total"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
        <el-dialog
                :title="handleStatus==1?'资料登记':(handleStatus==2?'资料编辑':'资料查看')"
                :visible.sync="dialogVisible"
                :close-on-click-modal="false"
                width="50%"
               >
          <div style="height: 500px;overflow: auto">
          <!-- v-model="activeNames" -->
            <el-collapse accordion >
                    <el-collapse-item v-for="(item,index) in Datalist" :key="index" :name="index">
                        <template slot="title">
                            <span class="title">{{item.templateName}}</span>
<!--         修改和查看详情时候显示图标 审核通过，不过，待审核三种状态-->
                            <el-tag v-if="handleStatus!=1&&item.templateCheck==1" type="success" style="margin-left:10px ">已通过</el-tag>
                            <el-tag v-if="handleStatus!=1&&item.templateCheck==2" type="warning" style="margin-left:10px ">未审核</el-tag>
                            <el-tag v-if="handleStatus!=1&&item.templateCheck==3" type="danger" style="margin-left:10px ">已驳回</el-tag>
                        </template>
                        <div>
<!--                            //描述-->
                            <p class="direct">{{item.templateDescribe}}</p>
<!--                            驳回原因-->
                            <p v-if="item.templateCheck==3">驳回原因：{{item.dismissReason}}</p>
<!--                            登记||驳回的时候重新登记 审核完成的不显示登记-->
                            <el-button size="small" type="primary" v-if="handleStatus!=3&&item.templateCheck!=1"  @click="handelSubmit(item)" style="margin: 10px 0">登记</el-button>
                            <el-table
                                    ref="tableData"
                                    :data="item.recordList"
                                    style="width: 100%;"
                                    border
                                    :stripe="true">
                                <el-table-column prop="dataName" label="标题"  align="center" show-overflow-tooltip> </el-table-column>
                                <el-table-column prop="createTime" label="创建时间" align="center" show-overflow-tooltip></el-table-column>
                                <el-table-column prop="updateBy" label="末次修改" align="center" show-overflow-tooltip></el-table-column>
                                <el-table-column prop="updateTime" label="修改时间" align="center" show-overflow-tooltip></el-table-column>
                                <el-table-column label="操作" align="left" width="150">
                                    <template slot-scope="{row}">
                                        <el-button size="small" type="primary" @click="modifyMenu(row)" v-if="handleStatus!=3&&item.templateCheck!=1">修改</el-button>
                                        <el-button size="small" type="danger" @click="delMenu(row)" v-if="handleStatus!=3&&item.templateCheck!=1">删除</el-button>
                                        <el-button size="small" type="primary" @click="seeMenu(item,row)" v-if="handleStatus==3||item.templateCheck==1">查看</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </el-collapse-item>

            </el-collapse>
          </div>
            <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">关 闭</el-button>
  </span>
        </el-dialog>
      <el-dialog
          :visible.sync="menuDialogVisible"
          width="30%"
          :close-on-click-modal="false"
          @close="closemenu('submitObj')"
      >
        <div style="height: 500px;overflow: auto">
          <el-form ref="submitObj" :model="submitObj" label-width="150px" :rules="rule">
            <el-form-item label="标题:" prop="dataName">
              <el-input maxlength="20" show-word-limit name="limitLength" v-model="submitObj.dataName" ></el-input>
            </el-form-item>
            <div v-for="(item,index) in submitObj.workCompletionTemplatesSeedList" :key="index" style="position: relative">
              <el-form-item>
                <span slot="label" :class="item.templateSeedRequired==1?'requireTitle':''">{{item.templateSeedName}}</span>
                <div v-if="item.templateSeedType==1">
                  <!--             图片-->
                  <el-upload
                      ref="upload"
                      action="#"
                      :auto-upload="true"
                      :http-request="uploadImgContractAttachment"
                      :on-remove="(file)=>contractAttachmentDelete(file,index)"
                      list-type="picture-card"
                      accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
                      :file-list="item.templatesUrl"
                  >
                    <div style="height: 148px" @click="chooseItem(index)">
                      <img :src="uploadImg" />
                    </div>
                  </el-upload>
                </div>
                <div v-if="item.templateSeedType==2">
                  <!--              文本描述-->
                  <el-input v-model="item.templatesUrl" type="textarea"></el-input>
                </div>
                <div v-if="item.templateSeedType==3">
                  <!--              PDF-->
                  <el-upload
                      ref="upload"
                      action="#"
                      :auto-upload="true"
                      :http-request="uploadImgContractAttachment"
                      :on-remove="(file)=>contractAttachmentDelete(file,index)"
                      accept=".pdf,.PDF"
                      :file-list="item.templatesUrl"
                  >
                    <el-button @click="chooseItem(index)">选择PDF文件导入</el-button>
                  </el-upload>
                </div>
              </el-form-item>
            </div>
          </el-form>
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button @click="menuDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveMenu('submitObj')">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
          :visible.sync="seeMenuDialogVisible"
          width="30%"
          :close-on-click-modal="false"
          @close="closEeeMenuDialogVisible"
      >
        <p class="DetiltemplateName">{{DetiltemplateName}}</p>
        <p class="direct" style="margin: 10px 0">{{DetiltemplateDescribe}}</p>
        <p style="margin: 20px 0">标题： {{submitObj.dataName}}</p>
        <div v-for="(item,index) in submitObj.workCompletionTemplatesSeedList" :key="index" style="margin: 20px 0">
          <div v-if="item.templateSeedType==1">
            <!-- 图片-->
            <div>{{item.templateSeedName}}： </div>
            <div v-if="item.templatesUrl.length>0">
              <el-image
                  style="width: 100px; height: 100px"
                  :src="item.templatesUrl[0]"
                  :preview-src-list="item.templatesUrl">
              </el-image>
              <span>共{{item.templatesUrl.length}}项</span>
            </div>

          </div>
          <div v-if="item.templateSeedType==2">
            <!--              文本描述-->
            <span>{{item.templateSeedName}}： </span>{{item.templatesUrl}}
          </div>
          <div v-if="item.templateSeedType==3">
            <!--              PDF-->
            <div>{{item.templateSeedName}}： </div>
            <div v-for="(i,k) in item.templatesUrl" :key="k">
              <a :href="i" download="" target="_back">查看PDF</a>
            </div>

          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="seeMenuDialogVisible = false">关 闭</el-button>
        </span>
      </el-dialog>
    </div>
</template>

<script>
    import { getDict } from "../../apis/commonType";
    import {
      beRegisteredList,
      becheckList,
      insertRegistrationData,
      operationData,
      viewMoreList,
      registerList,
      deleteRecord,
      verdictCheck, InsertBecheck
    } from "../../RequestPort/menu/menu"
    import axios from "@/apis/contractApi";
    import getNowFormatDate from "./../../common/js/nowDate.js";
    export default {
        name: "dataMember",
        data(){
            return{
              DetiltemplateName:'',
              DetiltemplateDescribe:'',
              rule:{
                dataName: [{ required: true, message: "请输入", trigger: "blur" }],
              },
              projectId:'',
              constructionTeamType:'',
              activeNames:0,
              handleStatus:1,
              showModifyStatus:1,
              dialogVisible:false,
              seeMenuDialogVisible:false,
              menuDialogVisible:false,
              activeName:0,
              searchBox:{
                    current:1,
                    size: 100,
                    isAll:1,
                  keyWord:''
              },
              uploadImg: require("@/assets/image/upload.png"),
              typeList:[],
              total:null,
              tableData:[],
              Datalist:[],
              submitDataList:[],
              chooseIndex:'',
              projectName:'',
              submitObj:{
                dataName:'',
                workCompletionTemplatesSeedList:[]
              }
            }
        },
        mounted(){
            //字典查工程类型
            getDict({ parentCode: "2100" }).then((res) => {
                this.typeList = res.data;
            });
          beRegisteredList(this.searchBox).then(res=>{
            this.tableData = res.data.records
            this.total = res.data.total
          })
        },
        methods:{
          closEeeMenuDialogVisible(){
            this.submitObj = {
              dataName:'',
              workCompletionTemplatesSeedList:[]
            }
            this.seeMenuDialogVisible = false
          },
          closemenu(form){
            this.submitObj = {
              dataName:'',
              workCompletionTemplatesSeedList:[]
            }

            this.$refs.upload.clearFiles();
            this.$refs[form].resetFields();
          },
          saveMenu(submitObj){
            this.$refs[submitObj].validate((valid) => {
              if (!valid) return false
              this.submitObj.workCompletionTemplatesSeedList.forEach(item=>{
                if(this.showModifyStatus == 1){
                  item.pid = item.id
                }
                if(item.templateSeedType==2){

                }else{
                  let arr = []
                  item.templatesUrl.forEach(i=>{
                    if(i.url){
                      arr.push(i.url)
                    }else{
                      arr.push(i)
                    }
                  })
                  item.templatesUrl = arr.toString()
                }
              })
              console.log(this.submitObj);
              operationData(this.submitObj).then(res=>{
                if(res.code===200){
                  this.$message.success(res.msg)
                  this.menuDialogVisible = false
                  //成功后，应该请求一次详情借口，
                  this.loadtemplate(this.projectId,this.constructionTeamType)
                }
              })
            })
          },
          //修改资料
          modifyMenu(row){
            this.showModifyStatus = 2
            let workCompletionTemplatesSeedList = row.workCompletionTemplatesSeedList
            workCompletionTemplatesSeedList.forEach(item=>{
              if(item.templatesUrl && typeof item.templatesUrl == 'string'){
                if(item.templateSeedType==2){

                }else {
                  this.$set(item,'templatesUrl',item.templatesUrl.split(','))
                  let arr = []
                  item.templatesUrl.forEach(i=>{
                    let obj = {
                      url:i,
                      name:i.split('/')[i.split('/').length-1]
                    }
                    arr.push(obj)
                  })
                  this.$set(item,'templatesUrl',arr)
                }
              }else if(!item.templatesUrl){
                 if(item.templateSeedType==2){

                 }else{
                   this.$set(item,'templatesUrl',[])
                 }
              }
            })
            this.submitObj  = JSON.parse(JSON.stringify(row))
            this.menuDialogVisible = true
          },
          //查看资料
          seeMenu(item,row){
            this.DetiltemplateName = item.templateName
            this.DetiltemplateDescribe = item.templateDescribe
            this.submitObj = JSON.parse(JSON.stringify(row))
            this.submitObj.workCompletionTemplatesSeedList.forEach(item=>{
              if(item.templatesUrl && typeof item.templatesUrl == 'string'){
                if(item.templateSeedType==2){

                }else {
                  this.$set(item,'templatesUrl',item.templatesUrl.split(','))
                }
              }else if(!item.templatesUrl){
                if(item.templateSeedType==2){

                }else{
                  this.$set(item,'templatesUrl',[])
                }
              }
            })
            this.seeMenuDialogVisible = true
          },
          delMenu(row){
            this.$confirm('是否删除该条纪录?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              closeOnClickModal:false,
              type: 'warning'
            }).then(() => {
              deleteRecord({id:row.id}).then(res=>{
                if(res.code===200){
                  this.$message.success(res.msg)
                  this.loadtemplate(this.projectId,this.constructionTeamType)
                }
              })
            })
          },
          handelSubmit(item){
            this.showModifyStatus = 1
            this.submitObj.templateId = item.id
            let workCompletionTemplatesSeedList = JSON.parse(JSON.stringify(item.templateSeed))
            workCompletionTemplatesSeedList.forEach(item=>{
              //這裏需要判斷一下是神惡魔類型
              if(item.templateSeedType==2){
                item.templatesUrl = ''
              }else{
                item.templatesUrl = []
              }
            })
            this.submitObj.workCompletionTemplatesSeedList = workCompletionTemplatesSeedList
            this.menuDialogVisible = true
          },
          handleClick(tab){
            this.searchBox.current = 1
            this.searchBox.keyWord = ''
            if(tab.index==0){
              //登记列表
              beRegisteredList(this.searchBox).then(res=>{
                this.tableData = res.data.records
                this.total = res.data.total
              })
            }else if(tab.index==1){
              //資料審核
              becheckList(this.searchBox).then(res=>{
                this.tableData = res.data.records
                this.total = res.data.total
              })
            }else {
              //資料審核完成
              viewMoreList(this.searchBox).then(res=>{
                this.tableData = res.data.records
                this.total = res.data.total
              })

            }
          },
          submitMenu(row){
            if(this.activeName==0){
              verdictCheck({constructionId:row.constructionId,projectId:row.id}).then(res=>{
                if(res.code==200&&res.data.type==1){
                  //登记列表的提交审核
                  this.$confirm('是否提交审核?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    closeOnClickModal:false,
                    type: 'warning'
                  }).then(() => {
                    insertRegistrationData({constructionId:row.constructionId,projectId:row.id}).then(res=>{
                      if(res.code===200){
                        this.$message.success(res.msg)
                        this.handleClick({index:0})
                      }
                    })
                  })
                }else {
                  this.$message.error(res.data.name)
                }
              })
            }else {
              //审核中列表的提交审核
              this.$confirm('是否提交审核?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                closeOnClickModal:false,
                type: 'warning'
              }).then(() => {
                InsertBecheck({constructionTeamType:row.constructionTeamType,id:row.id}).then(res=>{
                  if(res.code===200){
                    this.$message.success(res.msg)
                    this.handleClick({index:1})
                  }
                })
              })
            }
          },
          loadtemplate(id,constructionType){
            registerList({id:id,constructionType:constructionType}).then(res=>{
              this.Datalist = res.data
            })
          },
          dengji(row,count){
              if(row.template.length>0){
                this.handleStatus = count
                this.constructionTeamType = row.constructionTeamType
                this.projectId = row.id
                this.projectName = row.projectName
                this.loadtemplate(row.id,row.constructionTeamType)
                this.dialogVisible = !this.dialogVisible
              }else{
                this.$message.error('该工程未配置模板！')
              }
            },
          chooseItem(index){
            this.chooseIndex = index
          },
          /**
           * 上传至阿里云
           */
          async uploadImgContractAttachment(files) {
            let uploadObj = new FormData();
            uploadObj.append("file", files.file);
            if(files.file.name.split('.')[1]!='pdf'&&files.file.name.split('.')[1]!='PDF'){
              uploadObj.set("text", '工程名称：'+this.projectName+'##当前时间：'+getNowFormatDate()+'##用户名：'+localStorage.getItem("userName"));
              let res = await axios.uploadImg1(uploadObj);
              this.submitObj.workCompletionTemplatesSeedList[this.chooseIndex].templatesUrl.push(res.data);
            }else{
              let res = await axios.uploadImg(uploadObj);
              this.submitObj.workCompletionTemplatesSeedList[this.chooseIndex].templatesUrl.push(res.data.url);
            }
          },
          /**
           * 合同附件删除文件
           */
          contractAttachmentDelete(file,index) {
            this.submitObj.workCompletionTemplatesSeedList[index].templatesUrl.forEach((item,i)=>{
              if(item.uid==file.uid){
                this.submitObj.workCompletionTemplatesSeedList[index].templatesUrl.splice(i,1)
              }
            })
          },
            search(){
              this.searchBox.current = 1
              if(this.activeName==0){
                //登记列表
                beRegisteredList(this.searchBox).then(res=>{
                  this.tableData = res.data.records
                  this.total = res.data.total
                })
              }else if(this.activeName==1){
                //資料審核
                becheckList(this.searchBox).then(res=>{
                  this.tableData = res.data.records
                  this.total = res.data.total
                })
              }else {
                //資料審核完成
                viewMoreList(this.searchBox).then(res=>{
                  this.tableData = res.data.records
                  this.total = res.data.total
                })

              }
            },
          /**
           * 每页显示条数变更
           */
          handleSizeChange(val) {
            this.searchBox.size = val;
            if(this.activeName==0){
              //登记列表
              beRegisteredList(this.searchBox).then(res=>{
                this.tableData = res.data.records
                this.total = res.data.total
              })
            }else if(this.activeName==1){
              //資料審核
              becheckList(this.searchBox).then(res=>{
                this.tableData = res.data.records
                this.total = res.data.total
              })
            }else {
              //資料審核完成
              viewMoreList(this.searchBox).then(res=>{
                this.tableData = res.data.records
                this.total = res.data.total
              })

            }
          },
          /**
           * 页签变更
           */
          handleCurrentChange(val) {
            this.searchBox.current = val;
            if(this.activeName==0){
              //登记列表
              beRegisteredList(this.searchBox).then(res=>{
                this.tableData = res.data.records
                this.total = res.data.total
              })
            }else if(this.activeName==1){
              //資料審核
              becheckList(this.searchBox).then(res=>{
                this.tableData = res.data.records
                this.total = res.data.total
              })
            }else {
              //資料審核完成
              viewMoreList(this.searchBox).then(res=>{
                this.tableData = res.data.records
                this.total = res.data.total
              })

            }
          },
        }
    }
</script>

<style scoped>
.direct{
  background-color: #F3F7FF;
  border: 1px dashed #C1DFFF;
  padding: 0 11px;
  color: #818181;
  border-radius: 4px;
}
.title{
  margin-left: 10px;
  color: #303133;
  font-size: 16px;
  position: relative;
}
.title:before{
  content: '';
  position: absolute;
  left: -10px;
  top: 16px;
  width: 3px;
  height: 16px;
  background: #8AC2FF;
}
.DetiltemplateName{
  margin-left: 10px;
  color: #303133;
  font-size: 16px;
  position: relative;
}
.DetiltemplateName:before{
  content: '';
  position: absolute;
  left: -10px;
  top: 5px;
  width: 3px;
  height: 16px;
  background: #8AC2FF;
}
    .content{
        width: 100%;
        background: #f3f4f8;
    }
    .contentbox {
        margin: 10px;
        padding: 10px;
        background-color:#fff;
        position: relative;
    }
.requireTitle:before{
  content:"*";
  color:#F56C6C;
  font-weight:bold;
  position: relative;
  top: 2px;
  left: -5px;
}
</style>